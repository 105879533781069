<template>
    <div class="d-flex flex-column gap-3">
        <div class="bg-white p-3 rounded" v-if="user.channel && secondaryChannels?.length">
            <b-form-group label="Canal:">
                <b-form-select class="w-100 border-grey rounded p-1" @change="getGroups" v-model="selectedChannel">
                    <b-form-select-option :value="channel" v-for="channel in [ user.channel, ...secondaryChannels]" :key="channel._id">
                        {{ channel.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>
        </div>
        <b-row class="m-0" v-if="!config">
            <b-col class="p-0 overflow-hidden d-flex flex-column" :style="`flex-grow: 1.75; height: calc((100dvh - ${subheaderPlusHeaderHeight}px) - 5em)`">
                <div class="py-2 px-3 d-flex align-items-center justify-content-between flex-shrink-0 bg-white">
                    <span class="d-flex" v-if="groupSelected">
                        <b-avatar class="bg-secondary text-white" size="2.75em" :src="verifyPhotoURL(groupSelected.photoURL)"></b-avatar>
                        <span class="ps-2 d-flex align-self-center">
                            <h6 class="text-secondary m-0">{{ groupSelected.name || groupSelected.number }}</h6>
                        </span>
                    </span>
                    <span class="invisible w-100" v-else>
                        <b-avatar class="bg-secondary text-white" size="2.75em"></b-avatar>
                    </span>
                    <span class="d-flex align-items-center">
                        <b-dropdown id="pickMedia" :class="{ 'invisible': !groupSelected }" menu-class="dropdownanexes" toggle-class="p-0 border-0 bg-transparent bg-transparent2" no-caret no-flip>
                            <template #button-content>
                                <b-icon class="darkenOnHover rounded-circle p-2 text-dark" icon="paperclip" font-scale="2.3" rotate="45"></b-icon>
                            </template>
                            <b-dropdown-item-button class="rounded-circle mt-0 dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(291deg 55% 58%)" @click="toggleInsertFile('image/*')" v-b-tooltip.hover.left="{ customClass: 'dropdownanexestooltip' }" title="Fotos">
                                <b-icon icon="image-fill"></b-icon>
                            </b-dropdown-item-button>
                            <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(88deg 50% 56%)" @click="toggleInsertFile('.mp4')" v-b-tooltip.hover.left="{ customClass: 'dropdownanexestooltip' }" title="Vídeos">
                                <b-icon icon="camera-video-fill"></b-icon>
                            </b-dropdown-item-button>
                            <!-- <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(4deg 67% 57%)" @click="toggleInsertFile('audio/*')" v-b-tooltip.hover.left="{ customClass: 'dropdownanexestooltip' }" title="Áudio">
                                <b-icon icon="mic-fill"></b-icon>
                            </b-dropdown-item-button> -->
                            <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(236deg 53% 59%)" 
                            @click="toggleInsertFile('application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow')" 
                            v-b-tooltip.hover.left="{ customClass: 'dropdownanexestooltip' }" title="Arquivo"
                            >
                            <b-icon icon="file-earmark-fill"></b-icon>
                            </b-dropdown-item-button>
                            <!-- <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(33deg 86% 57%)" v-b-tooltip.hover.left="{ customClass: 'dropdownanexestooltip' }" title="Localização">
                                <b-icon icon="geo-alt-fill"></b-icon>
                            </b-dropdown-item-button>
                            <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(199deg 94% 50%)" v-b-tooltip.hover.left="{ customClass: 'dropdownanexestooltip' }" title="Contato">
                                <b-icon icon="person-fill"></b-icon>
                            </b-dropdown-item-button> -->
                        </b-dropdown>
                        <b-tooltip custom-class="mt-1 top-0" target="pickMedia" title="Mídia" placement="bottom" boundary="document"></b-tooltip>
                        <b-icon id="config" class="darkenOnHover rounded-circle p-2 ms-2" icon="gear-fill" font-scale="2.4" role="button" @click="toggleConfig(); getOperatorsBothAccess()" v-show="groupSelected"></b-icon>
                        <b-tooltip custom-class="mt-1 top-0" target="config" title="Configurações" placement="bottom" boundary="document"></b-tooltip>
                    </span>
                </div>
                <div class="py-3 px-3 d-flex justify-content-between align-items-center flex-shrink-0 bg-green" v-if="!insertFile && !config">
                    <b-input-group class="d-flex align-items-center rounded-pill text-secondary w-100" :class="groupSelected ? 'bg-white' : 'bg-disabled'">
                        <template #prepend>
                            <b-icon class="bg-inherit mx-2" icon="search" :role="groupSelected ? 'button' : 'text'"></b-icon>
                        </template>
                        <b-form-input type="search" class="border-0 shadow-none rounded-pill p-2" 
                        :placeholder="groupSelected ? 'Procurar Mensagem' : 'Nenhum Grupo selecionado!'" 
                        :disabled="!groupSelected"
                        style="font-size: 0.95em; height:2.65em; border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;" 
                        v-model="inputSearchGroupMsg"
                        @input="searchGroupMsg"
                        ></b-form-input>
                    </b-input-group>
                    <div class="d-flex align-items-center ms-2 text-white" v-if="inputSearchGroupMsg">
                        <div class="w-max border-bottom border-white">
                            {{ msgSearchActual }} de {{ msgSearchTotal }}
                        </div>
                        <b-icon class="mx-2 darkenTextOnHover" icon="chevron-up" @click="goToMessageSearch('up')" role="button"></b-icon>
                        <b-icon class="darkenTextOnHover" icon="chevron-down" @click="goToMessageSearch('down')" role="button"></b-icon>
                    </div>
                </div>
                <div class="px-2 text-white d-flex align-items-center w-100 flex-shrink-0" style="background-color: hsl(87deg 55% 65%); font-weight: 600; padding: 0.76em" v-else-if="!config">
                    <b-icon class="icon" icon="x" font-scale="3" role="button" @click="toggleInsertFile()"></b-icon>
                    <div style="font-size: 1.25em">Pré-Visualizar</div>
                </div>
                <Whatsapp class="whatsappMsgs h-100 overflow-y-auto position-relative" :height="'100%'" :messages="groupSelected?.msgs?.length ? groupSelected.msgs : []" :user="user" :mediaURL="mediaURL" :timestampDiff="timestampDiff" @setReplyMessageEvent="setReplyMessage" :showLoadMessagesButton="!!groupSelected" v-if="!insertFile && !config"/>
                <div class="insertFileBackground p-3 px-4 d-flex flex-column h-100" style="background-color: hsl(26deg 24% 87%);" v-else-if="!config">
                    <b-form class="inputFileForm h-100">
                        <div class="position-relative h-100">
                            <b-form-file
                            @input="setInputMedia"
                            class="inputFile" v-bind:class="{'border-0':mediaToSend.length}"
                            :accept="fileParam"
                            drop-placeholder="SOLTAR AQUI"
                            multiple
                            >
                                <template slot="placeholder">
                                    <div>
                                        <div class="addMediaWrapper d-flex flex-column align-items-center" v-if="!mediaToSend.length || mediaToSend.length<1">
                                            <div><b-icon scale="3" icon="plus"></b-icon></div>
                                            <div>Adicionar</div>
                                            <div>Arquivo</div>
                                        </div>
                                    </div>
                                </template>
                                <template slot="file-name">
                                    <div class="invisible"></div>
                                </template>
                            </b-form-file>
                            <img class="position-absolute top-0 start-50 translate-middle-x mh-100 mw-100" :src="mediaToSend[mediaToSend.length-1].url" v-if="mediaToSend.length && mediaToSend[mediaToSend.length-1].type=='image'">
                            <video class="position-absolute top-0 start-50 translate-middle-x mh-100 mw-100" :src="mediaToSend[mediaToSend.length-1].url" v-else-if="mediaToSend.length && mediaToSend[mediaToSend.length-1].type=='video'"></video>
                            <div class="position-absolute p-3 bg-white d-flex flex-column align-items-center justify-content-center top-0 start-50 translate-middle-x mh-100 mw-100" v-else-if="mediaToSend.length && mediaToSend[mediaToSend.length-1].type=='audio'">
                                <b-icon class="mb-3" font-scale="4" icon="file-earmark-music"></b-icon>
                                {{mediaToSend[mediaToSend.length-1].name}}
                            </div>
                            <div class="position-absolute p-3 bg-white d-flex flex-column align-items-center justify-content-center" style="height: 100%; width: 50%; left:50%; transform: translateX(-50%); top: 0" v-else-if="mediaToSend.length && (mediaToSend[mediaToSend.length-1].type=='document' || mediaToSend[mediaToSend.length-1].type=='application')">
                                <b-icon
                                    class="mb-3" 
                                    :icon="(fileExt(mediaToSend[mediaToSend.length-1].name)=='csv' || fileExt(mediaToSend[mediaToSend.length-1].name)=='xlsx') ? 'file-earmark-spreadsheet-fill' : 'file-earmark-fill'" 
                                    :class="{ 'text-success': (fileExt(mediaToSend[mediaToSend.length-1].name)=='csv' || fileExt(mediaToSend[mediaToSend.length-1].name)=='xlsx'), 'text-danger': fileExt(mediaToSend[mediaToSend.length-1].name)=='pdf'}" 
                                    font-scale="4"
                                ></b-icon>
                                {{mediaToSend[mediaToSend.length-1].name}}
                            </div>
                        </div>
                    </b-form>
                    <div class="position-relative" v-if="currentMedia && (currentMedia.type.includes('image') || currentMedia.type.includes('video'))">
                        <b-input class="inputFileSub" placeholder="Legenda" v-model="inputSend" @input="setCaptionMedia"></b-input>
                        <b-icon class="inputFileSubEmoji darkenTextOnHover" icon="emoji-smile" @click="toggleDialogEmoji"></b-icon>
                        <VEmojiPicker class="inputFileSubEmojiPicker" @select="selectEmoji" lang="pt-BR" v-show="showEmojiDialog" :i18n="i18n" />
                    </div>
                </div>
                <div class="flex-shrink-0" v-if="!insertFile && !config">
                    <div class="bg-msgInput pt-2 px-5" v-if="replyMessage?.body">
                        <div class="d-flex align-items-center justify-content-between bg-silver mx-2 rounded border-start border-5" :class="replyMessage.fromMe ? 'border-success' : 'border-primary'" style="font-size: 0.9em">
                            <div class="ms-2">
                                <div class="fw-bold" :class="replyMessage?.fromMe ? 'text-success' : 'text-primary'">
                                    {{ replyMessage?.fromMe ? 'Você' : groupSelected.clientName }}
                                </div>
                                <div>
                                    {{ replyMessage?.body }}
                                </div>
                            </div>
                            <div>
                                <b-icon icon="x" font-scale="2" @click="replyMessage = {}" role="button"></b-icon>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between position-relative bg-msgInput" style="padding: 1.3em 1em" v-if="!recordedMedia?.url">
                        <VEmojiPicker class="inputFileSubEmojiPicker inputMessageEmojiPicker" @select="selectEmoji" lang="pt-BR" v-show="showEmojiDialog" :i18n="i18n" />
                        <b-icon class="me-2 text-secondary darkenTextOnHover" font-scale="2" icon="emoji-laughing" @click="toggleDialogEmoji"></b-icon>
                        <form class="w-100" @submit.prevent="sendMSG">
                            <!-- <b-input class="rounded-pill" :placeholder="(groupSelected._id && groupSelected.status != 'finished' && groupSelected.operatorId != operator._id) ? `Este atendimento está aberto com outro operador!` : `Digite uma Mensagem...`" v-model="inputSend"
                            :disabled="groupSelected.status=='finished' || groupSelected.operatorId != operator._id"
                            ></b-input> -->
                                <b-form-textarea
                                class="rounded-pill"
                                :placeholder="groupSelected?.deletedOnWhatsapp ? `Você não faz mais parte deste grupo. Ele ficará visível até ${this.$options.filters.date(new Date(groupSelected.whatsappDeletedAt).setFullYear(new Date(groupSelected.whatsappDeletedAt).getFullYear() + 1), 'DD/MM/YY')}` : 'Digite uma Mensagem...'"
                                v-model="inputSend"
                                :disabled="!groupSelected || groupSelected.status=='finished' || groupSelected.status=='abandoned' || groupSelected.inactive || groupSelected.deletedOnWhatsapp"
                                @keydown.enter.exact.prevent="sendMSG()"
                                rows="1"
                                max-rows="1"
                                no-resize
                                style="height: 1em"
                                ></b-form-textarea>
                        </form>
                        <span v-if="!recorder">
                            <b-icon class="ms-2 text-secondary darkenTextOnHover" icon="cursor-fill" font-scale="2" rotate="45" @click.prevent="sendMSG" v-if="inputSend.length > 0"></b-icon>
                            <b-icon class="ms-2 text-secondary darkenTextOnHover" icon="mic-fill" font-scale="2" @click="recAudio" v-else></b-icon>
                            <!-- <b-icon class="ms-2 text-secondary darkenTextOnHover" :icon="browser=='Firefox'?'mic-fill':'mic-mute-fill'" font-scale="2" @click="recAudio" v-else-if="operator && !operator.disableAudio"></b-icon> -->
                        </span>
                        <span class="d-flex" v-else>
                            <!-- <b-icon class="ms-2 text-secondary" icon="cursor-fill" rotate="45" font-scale="2" @click="sendAudio"></b-icon> -->
                            <div class="ms-2 d-flex align-items-center">{{recordingTime}}</div>
                            <b-icon class="ms-2 text-danger icon darkenTextOnHover" icon="stop-fill" font-scale="2" @click="confirmAudio"></b-icon>
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between position-relative" style="background-color: hsl(251deg 49% 93%); padding: 1em" v-else>
                        <b-icon class="me-2 text-danger icon darkenTextOnHover" icon="trash-fill" font-scale="2" @click="stopAudio" v-b-tooltip.hover.bottom title="Cancelar"></b-icon>
                        <video class="rounded audioplayer w-100" style="height:3em" controls>
                            <source :src="recordedMedia.url">
                        </video>
                        <b-icon class="ms-2 text-secondary darkenTextOnHover" icon="cursor-fill" rotate="45" font-scale="2" @click="sendAudio" v-b-tooltip.hover.bottom title="Enviar"></b-icon> 
                    </div>
                </div>
                <div class="position-relative d-flex align-items-center justify-content-center" style="background-color: hsl(251deg 49% 93%); height: 150px;" v-else-if="!config">
                    <b-button class="inputFileSendBtn p-3 btn-success rounded-circle" @click="sendInputMedia()"><b-icon icon="cursor-fill" rotate="45" scale="2"></b-icon></b-button>
                    <span v-if="mediaToSend.length>4">
                        <b-icon class="position-absolute bg-primary rounded-circle text-white p-1 btn border-0" font-scale="1.5" icon="chevron-left" style="left:4em" @click="scrollInputMediaLeft"></b-icon>
                        <b-icon class="position-absolute bg-primary rounded-circle text-white p-1 btn border-0" font-scale="1.5" icon="chevron-right" style="right:4em" @click="scrollInputMediaRight"></b-icon>
                    </span>
                    <div class="inputMediaArray d-flex align-items-center justify-content-center" style="overflow-x:hidden; width: 450px">
                        <span class="position-relative" v-for="item in mediaToSend" :key="item.id">
                            <b-icon font-scale="0.75" class="position-absolute darkenTextOnHover" style="right:1em; top:0.25em; z-index:1" icon="x-circle-fill" variant="danger" @click="removeMediaToSend(item)"></b-icon>
                            <img class="me-2" :src="item.url" style="max-width: 65px; height:65px" v-if="item.type=='image'">
                            <video class="me-2" :src="item.url" style="max-width: 65px; height:65px" v-else-if="item.type=='video'"></video>
                            <div class="me-2" style="max-width: 65px; height:65px" v-else-if="item.type=='audio'" v-b-tooltip="{customClass: 'mt-5'}" :title="item.name">
                                <b-icon class="p-2 bg-white" icon="file-earmark-music" style="height: 100%; width: 100%;"></b-icon>
                            </div>
                            <div class="me-2" style="max-width: 65px; height:65px" v-else-if="item.type=='document' || item.type=='application'" v-b-tooltip="{customClass: 'mt-5'}" :title="item.name">
                                <b-icon class="p-2 bg-white" icon="file-earmark" style="height: 100%; width: 100%;"></b-icon>
                                <div 
                                    style="position: absolute; left: 50%; bottom: 50%; font-size: 0.7em; color: #555; text-transform: uppercase; transform: translate(-65%,60%);"
                                >
                                    {{fileExt(item.name)}}
                                </div>
                            </div>
                        </span>
                        <b-form-file
                        @input="setInputMedia"
                        class="inputFile inputFileSmall"
                        drop-placeholder="SOLTAR AQUI"
                        multiple
                        v-if="mediaToSend.length"
                        >
                            <template slot="placeholder">
                                <div>
                                    <div class="d-flex flex-column align-items-center">
                                        <div><b-icon scale="3" icon="plus"></b-icon></div>
                                        <div>Adicionar</div>
                                        <div>Arquivo</div>
                                    </div>
                                </div>
                            </template>
                            <template slot="file-name">
                                <div>
                                    <div class="d-flex flex-column align-items-center">
                                        <div><b-icon scale="3" icon="plus"></b-icon></div>
                                        <div>Adicionar</div>
                                        <div>Arquivo</div>
                                    </div>
                                </div>
                            </template>
                        </b-form-file>
                    </div>
                </div>
            </b-col>
            <b-col class="p-0 overflow-hidden d-flex flex-column" :style="`height: calc((100dvh - ${subheaderPlusHeaderHeight}px) - 5em)`">
                <div class="py-2 px-3 d-flex align-items-center justify-content-between flex-shrink-0 bg-white border-start">
                    <span class="d-flex align-items-center">
                        <b-icon class="bg-purple text-white rounded-circle p-1" icon="people" font-scale="1.9"></b-icon>
                        <h6 class="ps-2 d-flex align-self-center text-secondary m-0">
                            Gestão de Grupos
                        </h6>
                        <b-avatar class="bg-secondary text-white invisible" size="2.853em" style="width: 1px"></b-avatar>
                    </span>
                </div>
                <div class="py-3 px-3 d-flex justify-content-between align-items-center flex-shrink-0 bg-grey">
                    <b-input-group class="d-flex align-items-center bg-white rounded-pill text-secondary w-100">
                        <template #prepend>
                            <b-icon class="bg-white ms-2" icon="search" role="button"></b-icon>
                        </template>
                        <b-form-input type="search" class="border-0 shadow-none rounded-pill" 
                        placeholder="Procurar Grupo" 
                        style="font-size: 0.95em; height:2.65em; border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;"
                        v-model="inputSearchGroup"
                        @input="getGroups(selectedChannel)"
                        ></b-form-input>
                    </b-input-group>
                </div>
                <div class="d-flex align-items-center justify-content-center h-100" v-if="loadingGroups">
                    <b-spinner label=""></b-spinner>
                </div>
                <div class="bg-white h-100 overflow-y-auto" v-else-if="groups.length">
                    <span v-for="item in groups" :key="item._id">
                        <div class="d-flex align-items-center justify-content-between darkenOnHover border-bottom p-3" role="button" @click.self="selectGroup(item)">
                            <div class="d-flex align-items-center w-100" @click="selectGroup(item)">
                                <b-avatar class="bg-secondary text-white" :src="item.photoURL"></b-avatar>
                                <div class="ms-2 fw-semibold text-purple">{{ item.name }}</div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="bg-red text-white px-2 rounded-pill" style="padding: .1em 0" v-if="item.messages">
                                    {{ item.messages }}
                                </div>
                                <div class="d-flex align-items-center align-self-center bg-primary rounded-circle text-white ms-2 rounded-pill px-1" 
                                    style="height: min-content"
                                    v-if="item.newMsgs"
                                    @click="selectGroup(item)"
                                >
                                    {{item.newMsgs}}
                                </div>
                                <span v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document', variant: `${item.inactive ? 'purple' : 'warning'}` }" :title="item.inactive ? 'Reativar Grupo' : 'Inativar Grupo'" v-b-modal.inactiveGroup @click="selectGroupToEdit(item)">
                                    <b-icon :class="`text-white p-2 ms-2 rounded-circle darkenOnHover ${item.inactive ? 'bg-purple' : 'bg-warning'}`" font-scale="2" scale="1.25" icon="people" flip-h></b-icon>
                                </span>
                                <span v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document', variant: 'red' }" title="Deletar Grupo" v-b-modal.deleteGroup @click="selectGroupToEdit(item)">
                                    <b-icon class="bg-red text-white p-2 ms-2 rounded-circle darkenOnHover" font-scale="2" scale="1.25" icon="trash"></b-icon>
                                </span>
                                <b-icon class="bg-purple text-white p-1 ms-2 rounded-circle darkenOnHover" font-scale="2" icon="chevron-down" @click="showContacts(item)"></b-icon>
                            </div>
                        </div>
                        <div v-if="item.showContacts">
                            <div class="bg-light text-secondary text-center py-2 border-bottom" v-if="!item.participants?.length">
                                Nenhum Contato Encontrado!
                            </div>
                            <div v-else>
                                <div v-for="contact in item.participants" :key="contact._id">
                                    <div class="d-flex align-items-center p-3 bg-light border-bottom">
                                        <b-avatar class="bg-secondary text-white" :src="contact.photoURL"></b-avatar>
                                        <div class="ms-2 text-secondary fw-semibold">
                                            {{ contact.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-center bg-light text-secondary h-100 w-100" v-else>
                    Nenhum Grupo Encontrado!
                </div>
            </b-col>
        </b-row>
        <b-row class="m-0" v-else>
            <b-col class="test p-0 overflow-hidden d-flex flex-column" :style="`height: calc((100dvh - ${subheaderPlusHeaderHeight}px) - 5em)`">
                <b-row class="m-0 bg-white">
                    <b-col class="p-0" style="flex-grow: 1.75;">
                        <div class="py-2 px-3 d-flex align-items-center justify-content-between flex-shrink-0 bg-white">
                            <span class="d-flex align-items-center justify-content-between w-100">
                                <span class="d-flex" v-if="groupSelected">
                                    <b-avatar class="bg-secondary text-white" size="2.75em" :src="verifyPhotoURL(groupSelected.photoURL)"></b-avatar>
                                    <span class="ps-2 d-flex align-self-center">
                                        <h6 class="text-secondary m-0">{{ groupSelected.name || groupSelected.number }}</h6>
                                    </span>
                                </span>
                                <span class="invisible w-100" v-else>
                                    <b-avatar class="bg-secondary text-white" size="2.75em"></b-avatar>
                                </span>
                                <span class="d-flex align-items-center">
                                    <!-- <b-dropdown id="pickMedia" :class="{ 'invisible': !groupSelected }" menu-class="dropdownanexes" toggle-class="p-0 border-0 bg-transparent bg-transparent2" no-caret no-flip>
                                        <template #button-content>
                                            <b-icon class="darkenOnHover rounded-circle p-2 text-dark" icon="paperclip" font-scale="2.3" rotate="45"></b-icon>
                                        </template>
                                        <b-dropdown-item-button class="rounded-circle mt-0 dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(291deg 55% 58%)" @click="toggleInsertFile('image/*')" v-b-tooltip.hover.left="{ customClass: 'dropdownanexestooltip' }" title="Fotos">
                                            <b-icon icon="image-fill"></b-icon>
                                        </b-dropdown-item-button>
                                        <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(88deg 50% 56%)" @click="toggleInsertFile('.mp4')" v-b-tooltip.hover.left="{ customClass: 'dropdownanexestooltip' }" title="Vídeos">
                                            <b-icon icon="camera-video-fill"></b-icon>
                                        </b-dropdown-item-button>
                                        <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(236deg 53% 59%)" 
                                        @click="toggleInsertFile('application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow')" 
                                        v-b-tooltip.hover.left="{ customClass: 'dropdownanexestooltip' }" title="Arquivo"
                                        >
                                        <b-icon icon="file-earmark-fill"></b-icon>
                                        </b-dropdown-item-button>
                                    </b-dropdown>
                                    <b-tooltip custom-class="mt-1" target="pickMedia" title="Media" placement="bottom" boundary="document"></b-tooltip> -->
                                    <b-icon id="config" class="darkenOnHover rounded-circle p-2 ms-2" icon="gear-fill" font-scale="2.4" role="button" @click="toggleConfig"></b-icon>
                                    <b-tooltip custom-class="mt-1 top-0" target="config" title="Configurações" placement="bottom" boundary="document"></b-tooltip>
                                </span>
                            </span>
                        </div>
                    </b-col>
                    <b-col class="p-0">
                        <div class="py-2 px-3 d-flex align-items-center justify-content-between flex-shrink-0 bg-white border-start">
                            <span class="d-flex align-items-center">
                                <b-icon class="bg-purple text-white rounded-circle p-1" icon="people" font-scale="1.9"></b-icon>
                                <h6 class="ps-2 d-flex align-self-center text-secondary m-0">
                                    Gestão de Grupos
                                </h6>
                                <b-avatar class="bg-secondary text-white invisible" size="2.853em" style="width: 1px"></b-avatar>
                            </span>
                        </div>
                    </b-col>
                </b-row>
                <div class="bg-white h-100 border-top p-3" v-if="config">
                    <div class="configDiv d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center text-secondary">
                            <b-icon icon="gear" font-scale="2.75"></b-icon>
                            <div class="ms-2">
                                <div class="fw-bold fs-6">
                                    Configurações
                                </div>
                                <div class="small-text">
                                    Defina os operadores que vão atender o grupo.
                                </div>
                            </div>
                        </div>
                        <div class="w-fit">
                            <!-- <b-button class="rounded-pill px-4 fw-semibold bg-green border-0" @click="toggleConfig">Salvar</b-button> -->
                            <b-button class="rounded-pill px-4 fw-semibold bg-purple border-0 ms-3" @click="toggleConfig">Fechar</b-button>
                        </div>
                    </div>
                    <b-row class="m-0 mt-3" :style="`height: calc((100dvh - ${configDivHeight}px) - 12.5em)`">
                        <b-col class="p-0 overflow-hidden d-flex flex-column h-100">
                            <div class="bg-light-purple2 p-3 d-flex align-items-center justify-content-between flex-shrink-0">
                                <div class="fw-bold text-purple fs-6">Operadores sem Acesso</div>
                                <b-icon class="bg-purple text-white p-1 rounded-circle darkenOnHover" font-scale="1.8" icon="chevron-right" role="button"></b-icon>
                            </div>
                            <div class="p-3 bg-light-purple flex-shrink-0">
                                <b-input-group class="d-flex align-items-center rounded-pill bg-white text-secondary w-100">
                                    <template #prepend>
                                        <b-icon class="bg-inherit mx-2" icon="search" role="button"></b-icon>
                                    </template>
                                    <b-form-input type="search" class="border-0 shadow-none rounded-pill p-2" 
                                    placeholder="Procurar Operadores sem Acesso" 
                                    style="font-size: 0.95em; height:2.65em; border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;" 
                                    @input="searchOperatorsWithoutAccess"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="h-100 overflow-y-auto">
                                <div v-for="op in operatorsWithoutAccess" :key="op._id">
                                    <div class="d-flex align-items-center justify-content-between bg-light-grey p-3 border-bottom darkenOnHover">
                                        <div class="d-flex align-items-center">
                                            <b-avatar class="bg-secondary text-light"></b-avatar>
                                            <div class="ms-2 fw-semibold text-secondary">
                                                {{ op.name }}
                                            </div>
                                        </div>
                                        <b-icon class="bg-success text-white p-1 rounded-circle darkenOnHover" font-scale="1.8" icon="chevron-right" @click="updateOperatorAccess(op,true)" role="button"></b-icon>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="p-0 ms-4 overflow-hidden d-flex flex-column h-100">
                            <div class="bg-light-purple2 p-3 d-flex align-items-center">
                                <b-icon class="bg-purple text-white p-1 rounded-circle darkenOnHover" font-scale="1.8" icon="chevron-left" role="button"></b-icon>
                                <div class="fw-bold text-purple fs-6 ms-2">Operadores com Acesso</div>
                            </div>
                            <div class="p-3 bg-light-purple">
                                <b-input-group class="d-flex align-items-center rounded-pill bg-white text-secondary w-100">
                                    <template #prepend>
                                        <b-icon class="bg-inherit mx-2" icon="search" role="button"></b-icon>
                                    </template>
                                    <b-form-input type="search" class="border-0 shadow-none rounded-pill p-2" 
                                    placeholder="Procurar Operadores com Acesso" 
                                    style="font-size: 0.95em; height:2.65em; border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;" 
                                    @input="searchOperatorsWithAccess"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="overflow-y-auto">
                                <div v-for="op in operatorsWithAccess" :key="op._id">
                                    <div class="d-flex align-items-center bg-light-grey p-3 border-bottom darkenOnHover">
                                        <b-icon class="bg-success text-white p-1 rounded-circle darkenOnHover" font-scale="1.8" icon="chevron-left" @click="updateOperatorAccess(op,false)" role="button"></b-icon>
                                        <div class="d-flex align-items-center ms-2">
                                            <b-avatar class="bg-secondary text-light"></b-avatar>
                                            <div class="ms-2 fw-semibold text-secondary">
                                                {{ op.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <b-modal id="inactiveGroup" ref="inactiveGroup" :title="groupSelectedToEdit.inactive ? 'Reativar grupo' : 'Inativar Grupo'" header-class="py-1" hide-footer v-if="groupSelectedToEdit">
            <div class="vstack gap-3">
                <div>
                    <span v-if="groupSelectedToEdit.inactive">
                        Tem certeza que deseja reativar este grupo?
                    </span>
                    <span v-else>
                        Tem certeza que deseja inativar este grupo? Todos os históricos de conversas deste grupo estarão disponíveis até o momento da inativação.
                    </span>
                </div>
                <div class="hstack gap-2">
                    <b-button variant="success" @click="inactivateGroup">
                        Sim
                    </b-button>
                    <b-button variant="danger" @click="$bvModal.hide('inactiveGroup')">
                        Não
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="deleteGroup" ref="deleteGroup" title="Deletar Grupo" header-class="py-1" hide-footer v-if="groupSelectedToEdit">
            <div class="vstack gap-3">
                <div>
                    Tem certeza que deseja deletar este grupo? Todas as mensagens serão deletadas do histórico de conversas deste grupo.
                </div>
                <div class="hstack gap-2">
                    <b-button variant="success" @click="deleteGroup">
                        Sim
                    </b-button>
                    <b-button variant="danger" @click="$bvModal.hide('deleteGroup')">
                        Não
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<!-- <script type="application/javascript" defer src="https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OpusMediaRecorder.umd.js"></script>
<script type="application/javascript" defer src="https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/encoderWorker.umd.js"></script> -->
<script type="application/javascript" :src="`${window.location.protocol}//${window.location.host}/OpusMediaRecorder.umd.js`"></script>
<script type="application/javascript" :src="`${window.location.protocol}//${window.location.host}/encoderWorker.umd.js`"></script>

<script>
import Whatsapp from './whatsapp.vue'
import { VEmojiPicker } from 'v-emoji-picker'
import { getToken } from '../services/userService.js'
// import axios from 'axios'
import api from '../services/apiService.js'

const workerOptions = {
    encoderWorkerFactory: function () {
        return new Worker(`${window.location.protocol}//${window.location.host}/encoderWorker.umd.js`)
    },
    // OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
    // WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
    OggOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/OggOpusEncoder.wasm`,
    WebMOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/WebMOpusEncoder.wasm`
};

export default {
    components: {
        Whatsapp, VEmojiPicker
    },
    props: [
        'socket', 'user', 'subheaderPlusHeaderHeight', 'i18n', 'timestampDiff', 'mediaURL'
    ],
    async created() {
        const scripts = [
            `${window.location.protocol}//${window.location.host}/OpusMediaRecorder.umd.js`,
            `${window.location.protocol}//${window.location.host}/encoderWorker.umd.js`,
        ];
        const promises = scripts.map((script) => {
            const tag = document.createElement("script");
            tag.setAttribute("src", script);
            return new Promise((resolve, reject) => {
                tag.onload = resolve;
                tag.onerror = reject;
                document.head.appendChild(tag);
            });
        });
        await Promise.all(promises);
    },
    mounted() {
        setTimeout(() => {
            this.getGroups()
        }, 1000);
        this.getSecondaryChannels()
        // console.log('user',this.user)

        window.addEventListener("paste", this.handlePasteEvent, false);

        this.rootEvents()
        this.socketEvents()
        this.onCloseModal()
    },
    beforeDestroy() {
        this.socket.off('groups')
        this.socket.off('new_message_group')
        this.socket.off('message_react')
        this.socket.off('message_ack')
    },
    watch: {
        "config": {
            handler () {
                this.getConfigDivHeight()
            }
        },
    },
    data(){
        return {
            groups: [
                // {
                //     name: "test",
                //     messages: "10",
                // },
                // {
                //     name: "test 2",
                //     messages: "5",
                // },
            ],
            operators: [],
            operatorsWithAccess: [],
            operatorsWithoutAccess: [],
            departments: [],
            groupSelected: null,
            groupSelectedToEdit: null,
            configDivHeight: 0,
            groupContacts: [
                // {
                //     name: "contato"
                // },
                // {
                //     name: "contato"
                // },
            ],
            recorder: false,
            recordedMedia: null,
            replyMessage: null,
            showEmojiDialog: false,
            inputSend: '',
            currentMedia: null,
            inputMediaOverlay: false,
            insertFile: false,
            mediaToSend: [],
            recorder: false,
            chunks: [],
            recordInterval: '',
            recordingTime: '00:00',
            recordedMedia: {url:null,file:null},
            selectedMedia: '',
            file: [],
            fileParam: null,
            config: false,
            inputSearchGroup: "",
            inputSearchMsg: "",
            browserNotification: Notification.permission,
            inputSearchGroupMsg: "",
            msgSearchActual: 0,
            msgSearchTotal: 0,
            loadingGroups: false,
            secondaryChannels: [],
            selectedChannel: this.user.channel,
            audioExtension: 'ogg',
        }
    },
    methods: {
        async getGroups(channel = null) {
            this.config = false
            this.groupSelected = null
            this.loadingGroups = true;

            const channelId = channel?._id || this.user.channelId || this.user.roleId

            if(!channel)
                channel = this.user.channel

            if(channel?.apiType != "business") {
                return this.groups = []
            }

            if (this.socket.active) {
                this.socket.emit('get_groups', channelId);
            } else {
                await new Promise(resolve => setTimeout(resolve, 1000));
                this.getGroups(channel);
            }
        },
        async getOperatorsGroupAccess(status) {
            if (this.groupSelected) {
                const channelId = this.user.channelId || this.user.roleId;
                const { _id } = this.groupSelected;
                const resp = await api.getOperatorsGroupAccess(channelId, _id, status);
                // console.log('resp getOperatorsGroupAccess', status, resp);
                if (resp.statusCode == 200) {
                    if (status === 'enable') {
                        this.operatorsWithAccess = resp.operators;
                    } else if (status === 'disable') {
                        this.operatorsWithoutAccess = resp.operators;
                    }
                }
            }
        },
        getOperatorsBothAccess() {
            this.getOperatorsGroupAccess("enable")
            this.getOperatorsGroupAccess("disable")
        },
        async updateOperatorAccess(operator, status) {
            if(this.groupSelected) {
                const resp = await api.updateOperatorGroupAccess(this.groupSelected._id, this.user.channelId || this.user.roleId, operator._id, status)
                console.log('resp updateOperatorAccess',resp)
                if(resp.statusCode != 200) {
                    this.$emit('msg', {
                        text: "Ocorreu um erro!",
                        success: false
                    })
                }
                this.getOperatorsBothAccess()
            }
        },
        async getOperators(){
            const resp = await api.getOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200){
                this.operators = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            // console.log('operators',this.operators)
        },
        async getDepartments(){
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200){
                this.departments = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
            // console.log('departments',this.departments)
        },
        async selectGroup(group) {
            group.newMsgs = 0
            this.groupSelected = group
            this.groupSelected.previousMessagesCount = 0
            await this.loadGroupMessages(group)
            this.setScrollButton()
        },
        async selectGroupToEdit(group) {
            this.groupSelectedToEdit = group
        },
        async loadGroupMessages(group) {
            if(!group) group = this.groupSelected

            const resp = await api.loadGroupMessagesWeek({
                groupId: group._id,
            })
            console.log('messagesResp',resp)
            if(resp.statusCode != 200) {
                console.error('load_messages_group err',resp)
                this.$emit('msg', {
                    text: 'Ocorreu um erro ao carregar as mensagens!',
                    success: false
                })
                return
            }

            const msgs = []
            const messages = resp.messages?.messages || resp.messages
            for (const el of messages) {
                if(el.type=="vcard") {
                    if(!el.vcardName)
                        el.vcardName = el.body.split('FN:')[1].split('\n')[0]
                    el.vcardNumber = el.body.split('waid=')[1].split(':')[0]
                }

                msgs.push(el)
            }

            await this.$set(group, 'msgs', msgs)

            group.modelId = resp.messages._id

            group.newMsgs = 1
            group.newMsgs = 0

            this.setScrollButton()
        },
        async loadGroupPreviousMessages() {
            if(!this.groupSelected.previousMessagesCount)
                this.groupSelected.previousMessagesCount = 1
            else
                this.groupSelected.previousMessagesCount++

            const resp = await api.loadGroupPreviousMessages({ groupId : this.groupSelected._id, count: this.groupSelected.previousMessagesCount })
            if(resp.statusCode == 200) {
                if(resp.messages?.length) {
                    let dateAux
                    resp.messages.reverse().forEach(el => {
                        if(this.timestampDiff > 100)
                            el.timestamp -= this.timestampDiff
    
                        if(el.timestamp > 9999999999)
                            el.timestamp /= 1000
    
                        if(!dateAux) {
                            dateAux = new Date(el.timestamp*1000)
                            const year = dateAux.getFullYear()
                            const month = (dateAux.getMonth() + 1).toString().padStart(2, '0')
                            const day = dateAux.getDate().toString().padStart(2, '0')
                            dateAux = `${month}-${day}-${year}`
                        }
    
                        let date = new Date(el.timestamp*1000)
                        const year = date.getFullYear()
                        const month = (date.getMonth() + 1).toString().padStart(2, '0')
                        const day = date.getDate().toString().padStart(2, '0')
                        date = `${month}-${day}-${year}`
                        if(new Date(date) < new Date(dateAux)) {
                            const d = { type: 'date', timestamp: el.timestamp-1, date: dateAux } // timestamp here is wrong, as the dateAux comes from the previous message. if necessary, a fix would be to set a timestampAux to keep the timestamp from previous message
                            this.groupSelected.msgs.unshift(d)
                            dateAux = date
                        }
    
                        if(el.type=="vcard") {
                            try {
                                if(!el.vcardName)
                                    el.vcardName = el.body.split('FN:')[1].split('\n')[0]

                                if(!el.vcardNumber)
                                    el.vcardNumber = el.body.split('waid=')[1].split(':')[0]
                            } catch(err) {
                                console.error('vcard err',err)
                            }
                        }
                        this.groupSelected.msgs.unshift(el)
                    })
                }
            }

            this.$root.$emit('loadedGroupPreviousMessages',true)
        },
        showContacts(group) {
            this.$set(group,"showContacts",!group.showContacts)
        },
        verifyPhotoURL(url) {
            if(url){
                if(!url.includes('http'))
                    return `${this.mediaURL}${url}`
                return url
            }
        },
        toggleInsertFile(param=null) {
            this.insertFile = !this.insertFile
            this.mediaToSend = []
            this.file = []
            this.inputSend = ''
            this.currentMedia = null
            if(param)
                this.fileParam = param
        },
        toggleDialogEmoji() {
            this.showEmojiDialog = !this.showEmojiDialog;
        },
        selectEmoji(emoji) {
            this.inputSend += emoji.data
            if(this.currentMedia)
                this.currentMedia.caption += emoji.data
            this.toggleDialogEmoji()
        },
        async recAudio(){
            if(this.groupSelected?._id){
                // if(this.browser!='Firefox'){
                //     return this.$emit('msg', {
                //         text: "Este recurso só está disponível no navegador Firefox!",
                //         success: false
                //     })
                // }

                window.MediaRecorder = OpusMediaRecorder

                const pad = (val) => {
                    const valString = val + "";
                    if (valString.length < 2) {
                        return "0" + valString;
                    } else {
                        return valString;
                    }
                }

                navigator.mediaDevices.getUserMedia({ audio:true }).then(stream=> {
                    this.recorder = true
                    let totalSeconds = 0
                    let seconds = 0
                    let minutes = 0
                    this.recordInterval = setInterval(() => {
                        ++totalSeconds;
                        seconds = pad(totalSeconds % 60);
                        minutes = pad(parseInt(totalSeconds / 60));
                        this.recordingTime = minutes+':'+seconds
                    },1000);
                    this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/ogg; codecs=opus' }, workerOptions)
                    this.chunks = [];
                    this.mediaRecorder.ondataavailable = e => {
                        this.chunks.push(e.data)
                    }
                    this.mediaRecorder.start()
                })
                .catch(function(err) {
                    console.log('record',err)
                })
            }
        },
        confirmAudio(){
            clearInterval(this.recordInterval)
            this.recordingTime = ''

            this.mediaRecorder.stop()
            this.mediaRecorder.stream.getTracks().forEach(i => i.stop())

            this.recorder = false
            this.mediaRecorder.onstop = () => {
                const blob = new Blob(this.chunks, { 'type': 'audio/ogg; codecs=opus' })
                const file = new File([blob], `audio.${this.audioExtension}`,{ type: blob.type });
                this.recordedMedia.file = file

                const reader = new FileReader()
                reader.readAsDataURL(blob)
                
                reader.onloadend = () => {
                    const dataUrl = reader.result
                    this.recordedMedia.url = dataUrl 
                    // playAudio(dataUrl)
                    // let msg = {
                    //     body: '',
                    //     fromMe: true,
                    //     timestamp: Date.now()/1000,
                    //     type: 'audio',
                    //     mediaURL: dataUrl
                    // }
                    // this.groupSelected.msgs.push(msg)   
                }
            }
        },
        playAudio(url){
            const audio = new Audio(url)
            audio.play()
        },
        stopAudio(){
            this.recordedMedia = {url:null,file:null}
        },
        async sendAudio(){
            if(!this.groupSelected || !this.groupSelected._id)
                return
            const formData = new FormData()
            this.recordedMedia.file.agent = window.navigator.vendor
            console.log('file',this.recordedMedia.file)
            formData.append('file', this.recordedMedia.file);
            const token = getToken()
            const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile':'audio', 'clientNumber':this.groupSelected.clientNumber, 'authorization':token, 'from':'enviadas'}
            const uploadResp = await api.upload(formData,headers)
            console.log('uploadResp',uploadResp)
            // const filepath = resp.data.files['audio'].filepath.split('upload-files/')[1]
            const url = uploadResp.file.uploadDir
            const obj = {
                groupId: this.groupSelected._id, 
                url, 
                type: 'ptt', 
                caption: 'test', 
                filename:`audio${Date.now()}`,
                clientNumber: this.groupSelected.clientNumber,
                channelId: this.groupSelected.channelId,
                name: this.user.name
            }
            if(this.socket.active) {
                obj.isGroup = true
                this.socket.emit('send_message_group',obj)
            }
            const msg = {
                body: '',
                fromMe: true,
                timestamp: Date.now()/1000,
                type: 'audio',
                mediaURL: this.recordedMedia.url
            }
            this.groupSelected.msgs.push(msg)   
            this.recordedMedia = {
                url: null,
                file: null
            }
            this.groups.splice(this.groups.indexOf(this.groupSelected),1)
            this.groups.unshift(this.groupSelected);
            this.setScrollButton()
        },
        async sendMSG() {
            if (!this.groupSelected || !this.groupSelected._id) return

            if (this.inputSend.length > 0) {
                let quotedMsg = undefined
                if(this.replyMessage)
                    quotedMsg = Object.keys(this.replyMessage).length === 0 ? undefined : this.replyMessage

                const obj = {
                    msg: this.inputSend,
                    type: "text",
                    clientNumber: this.groupSelected.clientNumber,
                    channelId: this.selectedChannel._id,
                    groupId: this.groupSelected._id,
                    quotedMsg,
                    name: this.user.name,
                }
                if (this.socket.active) {
                    obj.isGroup = true;
                    this.socket.emit("send_message_group", obj);
                } else {
                    // let a = []
                    // if(JSON.parse(localStorage.getItem('msgs')) && JSON.parse(localStorage.getItem('msgs')).length)
                    //     a = JSON.parse(localStorage.getItem('msgs'));
                    // a.push(obj)
                    // localStorage.setItem('msgs', JSON.stringify(a));
                }
                // console.log(JSON.parse(localStorage.getItem('msgs')))
                const msg = {
                    body: this.inputSend,
                    fromMe: true,
                    timestamp: Date.now() / 1000,
                    statusAck: 0,
                    type: "text",
                    quotedMsg,
                }
                await this.groupSelected.msgs.push(msg)
                if (this.replyMessage) this.replyMessage = {}
                // console.log('msg',msg)
                this.inputSend = ""
                // this.groups.splice(this.groups.indexOf(this.groupSelected),1)
                // this.groups.unshift(this.groupSelected)
                this.groupSelected.newMsgs = 0
                this.setScrollButton()
            }
        },
        setScrollButton() {
            this.$root.$emit('scrollBottom',true)
        },
        setInputMedia(arrayMedia) {
            if(arrayMedia?.length) {
                arrayMedia.forEach(e => {
                    console.log('file',e)
                    const typefile = e['type'].split('/')[0]

                    if (this.user.channel.apiType === 'cloud' && typefile === 'image' && e.size > 5242800) {
                        return this.$emit('msg', {
                            text: "O tamanho máximo permitido para imagens é de 5 MB",
                            danger: true
                        })
                    }

                    if (e.size > 10000000) {
                        const input = document.querySelector('.inputFileForm')
                        input.reset()
                        return this.$emit('msg', {
                            text: "O tamanho máximo permitido para envios é de 10 MB",
                            success: false
                        })
                    }
                    if(e.type == 'video/x-matroska' || e.type == 'image/webp' || !e.type) {
                        const input = document.querySelector('.inputFileForm')
                        input.reset()
                        return this.$emit('msg', {
                            text: 'Extensão não suportada!',
                            success: false
                        })
                    }

                    const ext = e.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
                    if(this.containsUppercase(ext)) {
                        e = new File([e],e.name.replace(ext,ext.toLowerCase()), { type: e.type })
                    }

                    this.inputSend = ''
                    this.currentMedia = e
                    // this.file = []
                    this.file.push(e)
                    const reader = new FileReader()
                    reader.readAsDataURL(e)
                    reader.onload = () => {
                        const dataURL = reader.result
                        // this.mediaToSend = []
                        this.mediaToSend.push({url:dataURL.replace('base64','charset=utf-8;base64'),type:e['type'].split('/')[0],name:e['name']})
                    }
                })
            }
        },
        setCaptionMedia(e) {
            this.currentMedia.caption = e
        },
        async sendInputMedia() {
            if(!this.groupSelected || !this.groupSelected._id) {
                return
            }
            if(this.file.length>0) {
                let count = 0
                this.inputMediaOverlay = true
                await this.file.forEach(async (file) => {
                    const formData = new FormData();
                    const message = { body:'', mediaUrl:'' }
                    if(file.caption)
                        message.body = file.caption
                    let typefile = file['type'].split('/')[0]
                    if(typefile=='text')
                        typefile = 'file'
                    // if(type=='application'){
                    //     type = 'document'
                    // }
                    const media = this.mediaToSend.find(el=>el['name']==file.name)
                    const ext = file.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
                    // console.log('ext',ext)
                    // console.log(e)
                    const msg = {
                        body: file.caption,
                        fromMe: true,
                        timestamp: Date.now()/1000,
                        type: media.type,
                        mediaURL: media.url,
                        caption: message.body,
                        filename: file.name,
                        ext
                    }
                    file.filename = file.name
                    formData.append('file',file)
                    const token = getToken()
                    const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'clientNumber':this.groupSelected.clientNumber, 'authorization':token}
                    const uploadResp = await api.upload(formData,headers)
                    console.log('uploadResp',uploadResp)

                    msg.mediaURL = uploadResp?.file?.uploadDir

                    this.groupSelected.msgs.push(msg)
                    this.groups.splice(this.groups.indexOf(this.groupSelected),1)
                    this.groups.unshift(this.groupSelected)
                    // console.log(env,count)
                    // const filepath = uploadResp.file.path.split('upload/')[1]
                    // const url = `${process.env.VUE_APP_BASEURL}:5969/${filepath}`
                    const url = uploadResp.file.uploadDir
                    // console.log(message)
                    const obj = {
                        groupId: this.groupSelected._id,
                        url, 
                        type: typefile, 
                        caption: message.body, 
                        filename: file['name'].split('.')[0],
                        clientNumber: this.groupSelected.clientNumber,
                        channelId: this.groupSelected.channelId,
                        name: this.user.name
                    }
                    if(this.socket.active) {
                        obj.isGroup = true
                        this.socket.emit('send_message_group',obj)
                    }
                    else {
                        let a = []
                        if(JSON.parse(localStorage.getItem('msgs')) && JSON.parse(localStorage.getItem('msgs')).length)
                            a = JSON.parse(localStorage.getItem('msgs'));
                        a.push(obj)
                        localStorage.setItem('msgs', JSON.stringify(a));
                    }
                    count++
                    if(count==this.file.length) {
                        this.file = []
                        this.mediaToSend = []
                        this.inputSend = ''
                        this.inputMediaOverlay = false
                        await this.toggleInsertFile()
                        this.setScrollButton()
                    }
                })
            }
        },
        removeMediaToSend(item) {
            const input = document.querySelector('.inputFileForm')
            this.mediaToSend = this.mediaToSend.filter(el => el!=item)
            const file = this.file.find(el => el.name==item.name)
            this.file = this.file.filter(el => el != file)
            input.reset()
            const addMediaWrapper = document.querySelector('.addMediaWrapper')
            if(addMediaWrapper)
                addMediaWrapper.classList.remove("invisible")
        },
        toggleConfig() {
            this.config = !this.config
            if(this.config) {
                this.getOperators()
                this.getDepartments()
            }
        },
        getConfigDivHeight() {
            setTimeout(() => {
                const configDiv = document.querySelector('.configDiv')
                if(configDiv)
                    this.configDivHeight = configDiv.offsetHeight + this.subheaderPlusHeaderHeight
                else
                    this.getConfigDivHeight()
            },1000)
        },
        showNotification(messageText,contactName,contactPhotoURL) {
            if(document.visibilityState === "visible")
                return

            const notification = new Notification(contactName, { body: messageText, icon: contactPhotoURL || "https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c" })
            notification.onclick = () => { 
                notification.close()
                window.parent.focus()
            }
        },
        requestNotificationPermission() {
            if (!("Notification" in window))
                alert("Esse navegador não suporta notificações!");
            if(Notification.permission == "denied") {
                alert("Notificações estão bloqueadas pelo seu navegador! \nVocê pode desbloqueá-la nas configurações do seu navegador.")
            }
            Notification.requestPermission((permission) => {
               this.browserNotification = permission
            })
        },
        requestAndShowPermission() {
            Notification.requestPermission(function (permission) {
                if (permission === "granted") 
                    showNotification()
            })
        },
        sendBrowserNotification(data) {
            if ('Notification' in window) {
                // console.log('notification data',data)
                let message
                switch(data.messages?.type) {
                    case 'text':
                    case 'chat':
                        message = data.messages?.body
                    break;
                    case 'video':
                        message = "Vídeo"
                    break;
                    case 'image':
                        message = "Ímagem"
                    break;
                    case 'audio':
                        message = "Áudio"
                    break;
                    case 'location':
                        message = "Localização"
                    break;
                    case 'vcard':
                        message = "Contato"
                    break;
                    case 'document':
                    case 'application':
                        message = "Documento"
                    break;
                    case 'sticker':
                        message = "Sticker"
                    break;
                    case 'button_reply':
                        message = data.messages.body.button_reply.title
                    break;
                    case 'list_reply':
                        message = data.messages.body.list_reply.title
                    break;
                    default:
                        return
                }
    
                const permission = Notification.permission;
                if(permission === "granted") 
                    this.showNotification(message,data.attendance?.clientName || data.messages?.name, data.attendance?.photoURL);
                else if(permission === "default")
                    this.requestAndShowPermission();
            }
        },
        goToMessageSearch(direction = "up") {
            this.$root.$emit('goToMessageSearch',direction)
        },
        searchGroupMsg() {
            this.$root.$emit('searchGroupMsg',this.inputSearchGroupMsg)
        },
        toggleDialogReactEmoji(item) {
            item = this.groupSelected.msgs.find(el => el._id == item._id || el.body == item.body)
            if(!item.showReactEmojiDialog) item.showReactEmojiDialog = false
            item.showReactEmojiDialog = !item.showReactEmojiDialog
            this.$set(item,"timestamp",item.timestamp+1)
            this.$set(item,"timestamp",item.timestamp-1)
            this.selectedItemEmoji = item
            setTimeout(() => {
                if(this.$refs.inputReactEmojiPicker) {
                    this.$refs.inputReactEmojiPicker.focus()
                }
            }, 500);
        },
        async selectReactEmoji(emoji) {
            console.log('emoji',emoji)
            const msg = this.selectedItemEmoji
            this.toggleDialogReactEmoji(this.selectedItemEmoji)
            let channel = this.user.channel
            if(!channel) {
                const channelResp = await api.getChannel(this.user.channelId || this.user.roleId)
                if(channelResp.statusCode == 200)
                    channel = channelResp.channel.channel
            }
            if(channel) {
                const obj = {
                    modelId: this.groupSelected.modelId,
                    idWpp: msg.idWpp,
                    from: channel.channelNumber,
                    emoji: emoji.data,
                    to: this.groupSelected.from,
                    isGroup: true,
                    body: msg.body,
                    name: this.user.name
                }
                this.socket.emit('send_message_reaction', obj)
            }
        },
        async searchOperatorsWithoutAccess(input) {
            await this.getOperatorsGroupAccess("disable")
            if(input)
                this.operatorsWithoutAccess = this.operatorsWithoutAccess.filter(el => el.name.toLowerCase().includes(input.toLowerCase()))
        },
        async searchOperatorsWithAccess(input) {
            await this.getOperatorsGroupAccess("enable")
            if(input)
                this.operatorsWithAccess = this.operatorsWithAccess.filter(el => el.name.toLowerCase().includes(input.toLowerCase()))
        },
        setReplyMessage(message) {
            if(message.type == 'text')
                message.type == 'chat'
            this.replyMessage = {
                fromMe: message.fromMe,
                body: message.body,
                type: message.type,
                idWpp: message.idWpp
            }
            this.closeMsgOptions(message.timestamp)
            setTimeout(() => {
                this.setScrollButton()
            }, 250);
        },
        closeMsgOptions(id) {
            if(!isNaN(id))
                id = Math.trunc(id)
            const msgOptions = document.querySelector(`#msgOptions-${id}`)
            if(msgOptions)
                msgOptions.style.display = 'none'
        },
        closeMsgOptions(id) {
            if (!isNaN(id)) id = Math.trunc(id);
            const msgOptions = document.querySelector(`#msgOptions-${id}`);
            if (msgOptions) msgOptions.style.display = 'none';
        },
        async getSecondaryChannels() {
            const { channels } = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)
            this.secondaryChannels = channels.length ? channels : []
            // console.log('secondaryChannels',this.secondaryChannels)
        },
        handlePasteEvent(pasteEvent) {
            if (!this.insertFile) return

            const items = (pasteEvent.originalEvent || pasteEvent).clipboardData.items
            const blobs = []

            for (const item of items) {
                if (item.kind !== "file") continue

                blobs.push(item.getAsFile())
            }

            if (!blobs.length) {
                if (this.browser === "Firefox" && !pasteEvent.clipboardData.items.length) {
                    this.$emit("msg", {
                        text: "Área de Transferência vazia ou o Firefox não consegue identificar esse tipo de arquivo!",
                        success: false,
                    })
                }
                return
            }

            this.setInputMedia(blobs)

            const addMediaWrapper = document.querySelector(".addMediaWrapper")
            if (addMediaWrapper) addMediaWrapper.classList.add("invisible")
        },
        fileExt(filename) {
            if(filename && filename.split('').reverse().join('')) {
                return filename.split('').reverse().join('').split('.')[0].split('').reverse().join('');
            }
            return ''
        },
        containsUppercase(str) {
            return /^[A-Z]+$/.test(str);
        },
        async inactivateGroup() {
            if(this.groupSelectedToEdit) {
                const resp = await api.updateGroupField(this.selectedChannel._id, this.groupSelectedToEdit._id, { inactive: !this.groupSelectedToEdit.inactive })
                if(resp.statusCode == 200) {
                    this.groupSelectedToEdit.inactive = !this.groupSelectedToEdit.inactive
                    this.$bvModal.hide('inactiveGroup')
                    this.$emit("msg", { text: "Canal inativado com sucesso!", success: true })
                } else {
                    this.$emit("msg", { text: "Ocorreu um erro!", success: false })
                }
            }
        },
        async deleteGroup() {
            if (!this.selectedChannel) {
                this.selectedChannel = this.user.channel
            }
            if(this.groupSelectedToEdit) {
                const resp = await api.updateGroupField(this.selectedChannel._id, this.groupSelectedToEdit._id, { deleted: true })
                if(resp.statusCode == 200) {
                    this.$bvModal.hide('deleteGroup')
                    this.groups = this.groups.filter(el => el._id != this.groupSelectedToEdit._id)
                    this.$emit("msg", { text: "Canal deletado com sucesso!", success: true })
                } else {
                    this.$emit("msg", { text: "Ocorreu um erro!", success: false })
                }
            }
        },
        onCloseModal() {
            this.groupSelectedToEdit = null
        },
        rootEvents() {
            this.$root.$on('msgSearchTotal', (msgSearchTotal) => {
                this.msgSearchTotal = msgSearchTotal
            })

            this.$root.$on('msgSearchActual', (msgSearchActual) => {
                this.msgSearchActual = msgSearchActual
            })

            this.$root.$on('toggleDialogReactEmoji', (item) => {
                this.toggleDialogReactEmoji(item)
            })

            this.$root.$on('selectReactEmoji', (emoji) => {
                this.selectReactEmoji(emoji)
            })
            this.$root.$on('loadGroupPreviousMessages', () => {
                this.loadGroupPreviousMessages()
            })
        },
        socketEvents() {
            this.socket.on('groups', (data) => {
                console.log('groups',data)
                this.loadingGroups = false

                data = data.filter(el => !el.deleted)

                if(this.inputSearchGroup)
                    this.groups = data.filter(el => (el.name.toLowerCase().includes(this.inputSearchGroup.toLowerCase())) )
                else
                    this.groups = data
            })

            this.socket.on('new_message_group', async (data) => {
                console.log('new_message_group',data)
                if(data.messages?.phone == this.selectedChannel?.channelNumber || data.messages?.fromMe)
                    return
                if(data.messages.type == "vcard") {
                    if(!data.messages.vcardName)
                        data.messages.vcardName = data.messages.body.split('FN:')[1].split('\n')[0]
                    if(!data.messages.vcardNumber)
                        data.messages.vcardNumber = data.messages.body.split('waid=')[1].split(':')[0]
                }
                const chat = await this.groups.find(e => e.from == data.from)
                if(chat) {
                    this.sendBrowserNotification(data)
                    if(chat.msgs) {
                        if(typeof data.messages.timestamp == 'string') data.messages.timestamp = parseInt(data.messages.timestamp)
                        else if (data.messages.timestamp?.low) {
                            data.messages.timestamp = data.messages.timestamp.low
                        }
                        await chat.msgs.push(data.messages)
                        this.newMessage = true
                        setTimeout(() => {
                            this.newMessage = false
                        }, 2000);
                    }
                } else {
                    return console.log('chat não encontrado')
                }
                if(!chat.newMsgs) chat.newMsgs = 1  
                else chat.newMsgs++
                this.groups.splice(this.groups.indexOf(chat),1);
                this.groups.unshift(chat);
                this.playAudio('https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fmixkit-message-pop-alert-2354.mp3?alt=media&token=e76c575b-4ce7-4e57-8408-834229b653bf')
                // if(data.from == this.groupSelected.from)
                //     this.setScrollButton()
            })

            this.socket.on('message_ack', async (data) => {
                console.log('message_ack',data)
                if(!this.groupSelected || !this.groupSelected.msgs || !this.groupSelected.msgs.length) return
                switch(data.statusAck) {
                    case 1:
                    case '1': {
                        let messageIndex = this.groupSelected?.msgs.findLastIndex(el => el.fromMe && el.statusAck && el.idWpp)
                        messageIndex = this.groupSelected?.msgs.findIndex((el,i) => el.fromMe && !el.statusAck && !el.idWpp && i > messageIndex)
                        // console.log('messageIndex',messageIndex)
                        if(messageIndex == -1)
                            return await this.loadGroupMessages()

                        // this.groupSelected.msgs[messageIndex].statusAck = data.statusAck
                        await this.$set(this.groupSelected.msgs[messageIndex], 'statusAck', data.statusAck)
                        this.groupSelected.msgs[messageIndex].idWpp = data.idWpp
                        this.groupSelected.newMsgs++
                        this.groupSelected.newMsgs--
                        break
                    }
                    default: {
                        const message = this.groupSelected.msgs.find(e => e.idWpp == data.idWpp)
                        // console.log('message',message)
                        if(message)
                            await this.$set(message, 'statusAck', data.statusAck)
                        else
                            return await this.loadGroupMessages()
                        break
                    }
                }
            })

            this.socket.on('message_react', (data) => {
                console.log('message_react',data)
                const msg = this.groupSelected.msgs?.find(el => el.idWpp == data.idWpp)
                if(msg) {
                    if(!msg.react)
                        msg.react = [ data.react ]
                    else {
                        const index = msg.react.findIndex(el => el.contactId == data.react.contactId)
                        if(index != -1)
                            msg.react[index] = data.react
                        else
                            msg.react.push(data.react)
                    }
                    msg.timestamp++
                    msg.timestamp--
                }
            })
        }
    }
}
</script>

<style>
    .whatsappMsgs .waBg {
        border-radius: 0 !important;
    }
    .dropdownanexes{
        background-color: transparent !important;
        border-color: transparent !important;
        flex-direction: column;
        min-width: fit-content;
        cursor: default;
        margin-left: -0.3em;
        z-index: 2;
    }
    .dropdownanexes .b-icon{
        color: #fff;
        font-size: 1.5em !important;
    }
    .dropdownanexes svg{
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }
    .dropdownanexesitem{
        margin: 15px 0;
        margin-left: -0.25em;
        box-shadow: 1.4px 2px 5px #555 !important;
    }
    .dropdownanexesitem:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .dropdownanexesitembtn{
        padding: 12px;
        padding-bottom: 8px;
        width: fit-content;
        background-color: transparent !important;
    }
    .inputFileSmall{
        height: 65px !important;
        width: 65px;
    }
    .inputFileSmall .custom-file-label{
        height: 65px !important;
        border: none;
        font-size: 0.5em;
        background-color: white !important;
        width: 65px;
        margin-bottom: 1em;
    }
    .inputFile{
        height: 100%;
    }
    .inputFile input, .inputFile .custom-file-label::after{
        display: none;
    }
    .inputFile.border-0 .custom-file-label{
        border: none;
    }
    .inputFile .custom-file-label{
        background-color: transparent;
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: hsl(246deg 15% 41%);
        border-color: hsl(246deg 29% 77%);
        border-radius: 0;
        text-transform: uppercase;
    }
    .inputFileSendBtn{
        position: absolute;
        right: 1.2em;
        /* top: 0em; */
        border: none;
        box-shadow: -2px 5px 5px #555!important;
    }
    .inputFileSub{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid hsl(245deg 12% 63%) !important;
        border-radius: 0;
    }
    .inputFileSubEmoji{
        position: absolute;
        right: 0;
        top: 1em;
    }
    .inputFileSubEmojiPicker{
        position: absolute;
        top: -25em;
        right: 1em;
        z-index: 1;
        flex-direction: column-reverse !important;
    }
    .inputMessageEmojiPicker{
        right: unset;
        top: -26em;
        left: 0;
    }
</style>